/* .background-pattern {
  width: 100%;
  background-color: #f4f4f4; 
  background-image: radial-gradient(#e0e0e0 1px, transparent 1px),
    radial-gradient(#e0e0e0 1px, transparent 1px);
  background-size: 20px 20px; 
  background-position: 0 0, 10px 10px; 
} */

/* .background-pattern {
  background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
  background-size: 100px 100px;
  background-position: 0 0;
} */
/* You can modify the background-size and colors to match the exact look you want. */
/* .background-pattern {
  width: 100%;
  background-color: #f4f4f4; /* Base color */
/* overflow-x: hidden;
  /*  background-image: url('../img/background/background.png'),
    /* First image */ /* url('../img/background/2.png'),
    /* Second image */ /* url('../img/background/3.png'); /* Third image */
/*  background-repeat: repeat, repeat, repeat; /* Repeat each image */
/*background-position: center, top left, bottom right; /* Positioning each image */
/*  background-size: auto, auto, auto; /* Adjust size for each image */
/* min-height: 100vh; /* Ensures it covers the full height of the viewport */
/*} */
@font-face {
  font-family: 'Montserrat-Arabic';
  src: url('./Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
}

/* 
  background-image: radial-gradient(
      circle at 20% 30%,
      #e0e0e0 2px,
      transparent 2px
    ),
    radial-gradient(circle at 70% 80%, #e0e0e0 3px, transparent 8px),
    radial-gradient(ellipse at 60% 20%, #e0e0e0 6px, transparent 3px),
    radial-gradient(circle at 50% 50%, #e0e0e0 7px, transparent 4px),
    radial-gradient(circle at 40% 70%, #e0e0e0 4px, transparent 10px),
    radial-gradient(circle at 90% 10%, #e0e0e0 9px, transparent 5px);

  background-size: 50px 50px, 100px 100px, 150px 150px, 200px 200px, 250px 250px,
    300px 300px;
  background-position: 0 0, 50px 50px, 100px 100px, 150px 150px, 200px 200px,
    250px 250px;
  background-repeat: repeat; */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutFromLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}

@keyframes slideOutFromRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 1s ease-out forwards; /* Adjust duration and easing as needed */
}

.slide-in-from-right {
  animation: slideInFromRight 1s ease-out forwards; /* Adjust duration and easing as needed */
}

.slide-out-from-left {
  animation: slideOutFromLeft 1s ease-out forwards; /* Adjust duration and easing as needed */
}

.slide-out-from-right {
  animation: slideOutFromRight 1s ease-out forwards; /* Adjust duration and easing as needed */
}

.card-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: transparent;
}

.dot {
  position: absolute;
  width: 4px; /* Smaller size for the dots */
  height: 4px; /* Smaller size for the dots */
  border-radius: 50%; /* Makes it a circle */
  background: rgba(255, 255, 255, 0.6);
}

.line {
  position: absolute;
  height: 1px; /* Thickness of the line */
  background: rgba(255, 255, 255, 0.4);
  transform-origin: 0 0; /* For rotation */
}
/* main.css */
.custom-scrollbar::-webkit-scrollbar {
  background-color: #4e9b83;
  width: 12px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #64c290; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the thumb */
  border: 3px solid #f1f1f1; /* Padding around thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #559f82; /* Change color on hover */
}
.active {
  font-weight: bold;
  color: #64c290; /* Change color for active link */
}
.nav-link {
  margin-right: 14px;
  color: #000; /* Default link color */
  text-decoration: none;
  position: relative;
  font-family: 'Montserrat, sans-serif';
  /* font-size: 12px; /* Adjust based on isArabic */
  font-weight: normal;
  overflow: hidden;
  display: inline-block;
  padding-left: 8px; /* Default value for `pl: 1` */
  padding-right: 8px; /* Default value for `pr: 1` */

  /* Medium screens (md) */
}
.logo-container {
  margin-right: 8px;
  cursor: pointer;
}

.logo-container img {
  display: block;
  object-fit: cover;
  object-position: center;
}

/* Responsive sizing for the logo */
@media (max-width: 599.95px) {
  .logo-container {
    width: 200px;
    height: 40px;
  }
}

@media (min-width: 600px) and (max-width: 959.95px) {
  .logo-container {
    width: 250px;
    height: 50px;
  }
}

@media (min-width: 960px) {
  .logo-container {
    width: 300px;
    height: 60px;
  }
}
.nav-link:hover {
  color: #f00; /* Hover text color */
}

.nav-link .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #00f; /* Underline color */
  transition: width 0.3s ease;
}

.nav-link:hover .underline {
  width: 100%; /* Expand underline on hover */
}

.nav-link.active .underline {
  width: 100%; /* Keep underline expanded for active link */
}
